import AdminReportingApi from "@/generatedapiclients/src/api/AdminReportingApi.js";

export default class AdminReportingService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminReportingApi = new AdminReportingApi();
  }

  getFacilityChangeoverReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingFacilityChangeoverPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getCamperReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingCamperPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getCancelledReservationsReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingCancelledReservationsPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getClosureReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingClosurePost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getMaintenanceReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingMaintenancePost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getOccupancyByProductClassReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingOccupancyByProductClassPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getOccupancyByRevenueReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingOccupancyByRevenuePost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getReservationMethodReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingReservationMethodPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getVisitationReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingVisitationByResidencyPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getOverrideReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingOverridePost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getSiteMarkerReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingSiteMarkerPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getRevenueBreakupReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingRevenueBreakupPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getFieldDepositReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingFieldDepositPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getDisbursementReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingDisbursementPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  getAmenityDetailReport(reportRequest) {
    return this._adminReportingApi.v1TenantTenantIdAdminAdminReportingAmenityDetailPost(
      this._tenantId,
      {
        reportRequest
      }
    );
  }

  async forceFileDownload(response, filename) {
    let blob = "";
    if (window.navigator.msSaveOrOpenBlob) {
      blob = this.b64toBlob(response.reportFile, "application/pdf");
    } else {
      let base64response = await fetch(
        `data:application/pdf;base64,${response.reportFile}`
      );
      blob = await base64response.blob();
    }

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      let url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  }

  //Stackoverflow sweetness
  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
