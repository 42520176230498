/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ReportDeliveryMethodEnum from './ReportDeliveryMethodEnum';
import ReportFormatEnum from './ReportFormatEnum';

/**
 * The ReportRequest model module.
 * @module model/ReportRequest
 * @version v1
 */
class ReportRequest {
    /**
     * Constructs a new <code>ReportRequest</code>.
     * @alias module:model/ReportRequest
     */
    constructor() { 
        
        ReportRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ReportRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ReportRequest} obj Optional instance to populate.
     * @return {module:model/ReportRequest} The populated <code>ReportRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ReportRequest();

            if (data.hasOwnProperty('locationId')) {
                obj['locationId'] = ApiClient.convertToType(data['locationId'], 'Number');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('startDate')) {
                obj['startDate'] = ApiClient.convertToType(data['startDate'], 'Date');
            }
            if (data.hasOwnProperty('endDate')) {
                obj['endDate'] = ApiClient.convertToType(data['endDate'], 'Date');
            }
            if (data.hasOwnProperty('dateRun')) {
                obj['dateRun'] = ApiClient.convertToType(data['dateRun'], 'String');
            }
            if (data.hasOwnProperty('deliveryMethod')) {
                obj['deliveryMethod'] = ReportDeliveryMethodEnum.constructFromObject(data['deliveryMethod']);
            }
            if (data.hasOwnProperty('format')) {
                obj['format'] = ReportFormatEnum.constructFromObject(data['format']);
            }
            if (data.hasOwnProperty('faxNumber')) {
                obj['faxNumber'] = ApiClient.convertToType(data['faxNumber'], 'String');
            }
            if (data.hasOwnProperty('productClassIds')) {
                obj['productClassIds'] = ApiClient.convertToType(data['productClassIds'], ['Number']);
            }
            if (data.hasOwnProperty('emailAddresses')) {
                obj['emailAddresses'] = ApiClient.convertToType(data['emailAddresses'], ['String']);
            }
            if (data.hasOwnProperty('siteMarker4x4')) {
                obj['siteMarker4x4'] = ApiClient.convertToType(data['siteMarker4x4'], 'Boolean');
            }
            if (data.hasOwnProperty('sortByLoop')) {
                obj['sortByLoop'] = ApiClient.convertToType(data['sortByLoop'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} locationId
 */
ReportRequest.prototype['locationId'] = undefined;

/**
 * @member {String} username
 */
ReportRequest.prototype['username'] = undefined;

/**
 * @member {Date} startDate
 */
ReportRequest.prototype['startDate'] = undefined;

/**
 * @member {Date} endDate
 */
ReportRequest.prototype['endDate'] = undefined;

/**
 * @member {String} dateRun
 */
ReportRequest.prototype['dateRun'] = undefined;

/**
 * @member {module:model/ReportDeliveryMethodEnum} deliveryMethod
 */
ReportRequest.prototype['deliveryMethod'] = undefined;

/**
 * @member {module:model/ReportFormatEnum} format
 */
ReportRequest.prototype['format'] = undefined;

/**
 * @member {String} faxNumber
 */
ReportRequest.prototype['faxNumber'] = undefined;

/**
 * @member {Array.<Number>} productClassIds
 */
ReportRequest.prototype['productClassIds'] = undefined;

/**
 * @member {Array.<String>} emailAddresses
 */
ReportRequest.prototype['emailAddresses'] = undefined;

/**
 * @member {Boolean} siteMarker4x4
 */
ReportRequest.prototype['siteMarker4x4'] = undefined;

/**
 * @member {Boolean} sortByLoop
 */
ReportRequest.prototype['sortByLoop'] = undefined;






export default ReportRequest;

