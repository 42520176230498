<template>
  <div>
    <h3>{{ title }}</h3>
    <p>
      {{ description }}
    </p>
    <ul>
      <li v-for="(item, index) in bullets" :key="index">{{ item }}</li>
    </ul>
    <span v-if="locations">
      <ValidationObserver ref="report">
        <FormErrorAlert
          v-if="errors.length > 0"
          :errors="errors"
          :formRef="this.$refs.report"
        />
        <form @submit.prevent="onSubmit">
          <div class="row mb-2">
            <div class="col-md-4 col-sm-12">
              <label for="selectedLocation">Park</label>
              <select
                class="form-control form-control-lg"
                id="selectedLocation"
                v-model="selectedLocation"
                @change="filterUsers"
              >
                <option v-if="locations.length === 0" :value="null">
                  No Parks Available
                </option>
                <option
                  v-for="location in locations"
                  :key="location.id"
                  :value="location.id"
                >
                  {{ location.longName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="showUsers">
            <div class="col-md-4 col-sm-12">
              <label for="selectedUser">Users</label>
              <select
                class="form-control form-control-lg"
                id="selectedUser"
                v-model="selectedUser"
                placeholder="Select User"
              >
                <option v-if="users.length === 0" :value="null"> </option>
                <option
                  v-for="user in filteredUsers"
                  :key="user.id"
                  :value="user.username"
                >
                  {{ user.firstName }} {{ user.lastName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="showStartDate">
            <div class="col-md-4 col-sm-12">
              <DateInput
                vid="startDate"
                rules="required|isDate"
                :name="startDateLabel"
                id="startDate"
                v-model="startDate"
              />
            </div>
          </div>
          <div v-if="showEndDate" class="row mb-2">
            <div class="col-md-4 col-sm-12">
              <DateInput
                vid="endDate"
                :rules="
                  `${
                    endDateRequired == true ? 'required' : ''
                  }|isDate|departureDateMinusOne:@startDate|arrivalDateWithinDays:@startDate,${maxLength}`
                "
                :name="endDateLabel"
                :dateDisabledFn="minDepartureDate"
                :initialDate="initialDate"
                id="endDate"
                v-model="endDate"
              />
            </div>
          </div>
          <div class="row mb-2" v-if="showSortBy">
            <div class="col-md-4 col-sm-12">
              <label for="reportFormat">Report Sort Method</label>
              <select
                class="form-control form-control-lg"
                id="sortByLoop"
                v-model="sortByLoop"
              >
                <option value="false">Spot Name</option>
                <option value="true">Loop Name</option>
              </select>
            </div>
          </div>
          <div v-if="showProduct" class="row mb-2">
            <div class="col-md-4 col-sm-12">
              <label @click="$refs.select.focus()" for="productClassifications"
                >Product Classes</label
              >
              <multiselect
                v-model="selectedProductClassifications"
                label="name"
                track-by="id"
                :multiple="true"
                id="productClassifications"
                :clear-on-select="false"
                :preserve-search="true"
                open-direction="bottom"
                placeholder="Select Product Classes"
                :options="productClassifications"
                @open="openedProductClassifications"
                :loading="loadingProductClassifications"
              >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag" :key="option.id">
                    <span>{{ option.name }}</span>
                    <i
                      tabindex="1"
                      @keypress.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon"
                    ></i>
                  </span> </template
              ></multiselect>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 col-sm-12">
              <label for="reportFormat">Report Format</label>
              <select
                class="form-control form-control-lg"
                id="reportFormat"
                v-model="format"
                :disabled="deliveryMethod == 3 || pdfOnly || excelOnly"
              >
                <option value="1">PDF</option>
                <option value="2">Excel</option>
                <option value="3">CSV</option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="title === 'Site Markers'">
            <div class="col-md-4 col-sm-12">
              <label for="reportFormat">Marker Size</label>
              <select
                class="form-control form-control-lg"
                id="siteMarker4x4"
                v-model="siteMarker4x4"
              >
                <option value="false">4 X 3</option>
                <option value="true">4 X 4</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-4 col-sm-12">
              <label for="deliveryMethod">Delivery Method</label>
              <select
                class="form-control form-control-lg"
                id="deliveryMethod"
                v-model="deliveryMethod"
                :disabled="emailOnly"
              >
                <option value="1">Download</option>
                <option value="2">Email</option>
                <option value="3">Fax</option>
              </select>
            </div>
          </div>
          <div class="row mb-2" v-if="deliveryMethod == 2">
            <div class="col-md-4 col-sm-12">
              <TextInput
                rules="required|min:3|max:256"
                name="Email Address(es)"
                id="email"
                v-model="email"
                type="email"
                :multiple="true"
              />
              <small class="form-text text-muted mb-2"
                >Reporting tool distributes to emails entered in this field.
                Separate emails by comma.</small
              >
            </div>
          </div>
          <div class="row mb-2" v-if="deliveryMethod == 3">
            <div class="col-md-4 col-sm-12">
              <TextInput
                rules="required|maskNumMin:11"
                name="Fax Number"
                id="fax"
                v-model="fax"
                mask="# (###) ###-####"
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              class="btn btn-lg btn-primary mt-4 mr-2"
              :disabled="submitting"
            >
              <span v-if="!submitting">Get Report</span>
              <span
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              type="button"
              class="btn btn-lg btn-secondary mt-4"
              @click="$router.push('/admin/reporting').catch(() => {})"
            >
              Back
            </button>
          </div>
          <br />
          <p class="error">{{ reportStatus }}</p>
        </form>
      </ValidationObserver>
    </span>
    <span
      v-else
      class="spinner-border spinner-border-sm mx-3"
      role="status"
      aria-hidden="true"
    ></span>
  </div>
</template>

<script>
import LocationService from "@/services/LocationService.js";
import AdminUserService from "@/services/admin/AdminUserService.js";
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import { ValidationObserver } from "vee-validate";
import DateInput from "@/validation/DateInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import TextInput from "@/validation/TextInput.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  name: "AdminReportingForm",
  mixins: [checkPermissionMixin],
  props: {
    title: String,
    description: String,
    file: String,
    reportStatus: String,
    submitting: Boolean,
    showStartDate: {
      type: Boolean,
      default: true
    },
    showEndDate: {
      type: Boolean,
      default: true
    },
    showProduct: {
      type: Boolean,
      default: false
    },
    endDateRequired: {
      type: Boolean,
      default: true
    },
    pdfOnly: {
      type: Boolean,
      default: false
    },
    excelOnly: {
      type: Boolean,
      default: false
    },
    emailOnly: {
      type: Boolean,
      default: false
    },
    allowGlobal: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: "365"
    },
    showSortBy: {
      type: Boolean,
      default: false
    },
    startDateLabel: {
      type: String,
      default: "Start Date"
    },
    endDateLabel: {
      type: String,
      default: "End Date"
    },
    showUsers: {
      type: Boolean,
      default: false
    },
    bullets: Array
  },
  watch: {
    deliveryMethod() {
      if (this.deliveryMethod == 3) {
        this.format = 1;
      }
    }
  },
  data() {
    return {
      locations: null,
      selectedLocation: null,
      selectedUser: null,
      errors: [],
      startDate: null,
      endDate: null,
      format: 1,
      deliveryMethod: 1,
      email: null,
      fax: null,
      productClassifications: [],
      loadingProductClassifications: false,
      selectedProductClassifications: null,
      siteMarker4x4: false,
      sortByLoop: false,
      users: []
    };
  },
  components: {
    ValidationObserver,
    DateInput,
    FormErrorAlert,
    TextInput,
    Multiselect
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    filename() {
      let name = this.file;
      if (this.format == 1) {
        return name + ".pdf";
      } else if (this.format == 2) {
        return name + ".xlsx";
      } else if (this.format == 3) {
        return name + ".csv";
      } else {
        return null;
      }
    },
    initialDate() {
      if (this.startDate) {
        return moment(this.startDate, "MM-DD-YYYY").format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    userRoleId() {
      return this.$store.getters["auth/userRoleId"];
    }
  },
  methods: {
    minDepartureDate(ymd) {
      if (this.startDate) {
        return (
          moment(this.startDate, "MM-DD-YYYY")
            .add(this.maxLength, "days")
            .isSameOrBefore(ymd, "day") ||
          moment(this.startDate, "MM-DD-YYYY")
            .subtract(1, "days")
            .isSameOrAfter(ymd, "day")
        );
      } else {
        return false;
      }
    },
    async initialize() {
      const locationService = new LocationService(this.tenantId);
      const userService = new AdminUserService(this.tenantId);
      locationService.getAllLocations().then(response => {
        let locations = response.filter(x =>
          this.checkLocationPermission("ReportManagementReportManagement", x.id)
        );
        if (
          this.allowGlobal &&
          this.userRoleId &&
          (this.userRoleId == 1 || this.userRoleId == 2) &&
          this.checkPermission("ReportManagementReportManagement")
        ) {
          locations = [{ id: -1, longName: "Global" }, ...locations];
        }
        this.locations = locations;
        this.selectedLocation = locations[0].id;
      });

      if (this.showUsers) {
        this.users = await userService.getUsers({
          isNonCustomer: true,
          isNonSupport: true
        });
        this.filteredUsers = [...this.users];
      }
    },
    openedProductClassifications() {
      if (this.productClassifications.length == 0) {
        this.loadingProductClassifications = true;
        this.getProductClassifications();
      }
    },
    async getProductClassifications() {
      const productService = new AdminProductManagementService(this.tenantId);
      productService.getAllProductClasses().then(response => {
        response.forEach(item => {
          item.name = item.name + "-P" + item.id.toString().padLeft(3, "0");
        });
        this.productClassifications = response;
        this.loadingProductClassifications = false;
      });
    },
    filterUsers() {
      if (this.selectedLocation == -1) {
        this.filteredUsers = this.users;
        return;
      }

      this.filteredUsers = this.users.filter(
        x =>
          x.locations.length == 0 ||
          x.locations.map(l => l.locationId).includes(this.selectedLocation)
      );
    },
    onSubmit() {
      this.$refs.report.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.report.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.report.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          let reportData = {
            locationId: this.selectedLocation,
            username: this.selectedUser,
            startDate: this.startDate,
            endDate:
              this.endDate != null && this.endDate != ""
                ? this.endDate
                : this.startDate,
            productClassIds: this.selectedProductClassifications
              ? this.selectedProductClassifications.map(x => x.id)
              : null,
            deliveryMethod: this.deliveryMethod,
            format: this.format,
            faxNumber: this.fax,
            emailAddresses: this.email?.split(",").map(x => x.trim()),
            filename: this.filename,
            siteMarker4x4: this.siteMarker4x4,
            sortByLoop: this.sortByLoop
          };
          this.$emit("getReport", reportData);
        }
      });
    }
  },
  created() {
    if (this.pdfOnly == true) {
      this.format = 1;
    } else if (this.excelOnly == true) {
      this.format = 2;
    }

    if (this.emailOnly == true) {
      this.deliveryMethod = 2;
    }
    this.initialize();
  }
};
</script>

<style scoped>
.btn {
  border: none;
}
.multiselect {
  background-color: #fff;
}
</style>
