/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ReportRequest from '../model/ReportRequest';
import ReportResponse from '../model/ReportResponse';

/**
* AdminReporting service.
* @module api/AdminReportingApi
* @version v1
*/
export default class AdminReportingApi {

    /**
    * Constructs a new AdminReportingApi. 
    * @alias module:api/AdminReportingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingAmenityDetailPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingAmenityDetailPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/amenity-detail', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingAmenityDetailPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingAmenityDetailPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingCamperPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingCamperPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/camper', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingCamperPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingCamperPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingCancelledReservationsPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingCancelledReservationsPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/cancelled-reservations', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingCancelledReservationsPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingCancelledReservationsPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingClosurePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingClosurePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/closure', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingClosurePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingClosurePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingDisbursementPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingDisbursementPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/disbursement', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingDisbursementPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingDisbursementPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingFacilityChangeoverPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingFacilityChangeoverPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/facility-changeover', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingFacilityChangeoverPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingFacilityChangeoverPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingFieldDepositPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingFieldDepositPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/field-deposit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingFieldDepositPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingFieldDepositPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingMaintenancePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingMaintenancePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/maintenance', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingMaintenancePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingMaintenancePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingOccupancyByProductClassPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingOccupancyByProductClassPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/occupancy-by-product-class', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingOccupancyByProductClassPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingOccupancyByProductClassPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingOccupancyByRevenuePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingOccupancyByRevenuePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/occupancy-by-revenue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingOccupancyByRevenuePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingOccupancyByRevenuePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingOverridePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingOverridePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/override', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingOverridePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingOverridePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingReservationMethodPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingReservationMethodPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/reservation-method', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingReservationMethodPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingReservationMethodPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingRevenueBreakupPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingRevenueBreakupPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/revenue-breakup', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingRevenueBreakupPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingRevenueBreakupPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingSiteMarkerPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingSiteMarkerPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/site-marker', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingSiteMarkerPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingSiteMarkerPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ReportResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminReportingVisitationByResidencyPostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['reportRequest'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminReportingVisitationByResidencyPost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['application/octet-stream', 'application/json'];
      let returnType = ReportResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminReporting/visitation-by-residency', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/ReportRequest} opts.reportRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ReportResponse}
     */
    v1TenantTenantIdAdminAdminReportingVisitationByResidencyPost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminReportingVisitationByResidencyPostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
